








































































































































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import ClassworkMixin from '@/mixins/classwork-mixin';
import CommonUtils from '@/utils/common-utils';
import { Component } from 'vue-property-decorator';
import SearchFilter from '@/components/core/SearchFilter.vue';

@Component({
  mixins: [ClassworkMixin],
  components: {
    BaseToolbar,
    SearchFilter
  }
})
export default class ClassworkToolbar extends BaseToolbar {
  searchText!: string;
  editMode!: boolean;
  hasSelectedRows!: boolean;
  showScoreWeightDialog!: boolean;
  filterSelectionsSelectedClass!: any;
  filterOptionsClassList!: Array<any>;
  filterOptionsShowUnitFilter!: boolean;
  filterSelectionsSelectedUnit!: any;
  filterOptionsUnitList!: Array<any>;
  filterSelectionsShowClassworkTypes!: Array<any>;
  searchWidthGrow = '0';

  $refs!: {
    searchFilter: SearchFilter
  }

  get localClassworkComponent() {
    return this.currentPageInstance || {};
  }

  get arrayAddOrRemove() {
    return CommonUtils.arrayAddOrRemove;
  }

  focusMenu() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          (this.$refs as any).firstMenuItem.$el.querySelector('input').focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    });
  }

  focusButton() {
    this.$refs.searchFilter.focusButton();
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  addClasswork() {
    return this.localClassworkComponent.addClasswork();
  }

  deleteMultiplePopup() {
    return this.localClassworkComponent.deleteMultiplePopup();
  }

  clearFilter() {
    this.filterSelectionsSelectedClass = this.filterOptionsClassList[0];
    this.filterSelectionsShowClassworkTypes = [0, 1];
    this.searchText = '';
  }

  get isFiltered() {
    if (CommonUtils.hasNoText(this.searchText) && this.filterSelectionsSelectedClass === this.filterOptionsClassList[0] && this.filterSelectionsShowClassworkTypes.length === 2) {
      this.searchWidthGrow = '0';
      return false;
    } else {
      this.searchWidthGrow = '40';
      return true;
    }
  }

  get filterCount() {
    var count = 0;
    if (CommonUtils.hasText(this.searchText)) {
      count += 1;
    }
    if (this.filterSelectionsSelectedClass !== this.filterOptionsClassList[0]) {
      count += 1;
    }
    if (this.filterSelectionsSelectedUnit.id !== 0) {
      count += 1;
    }
    count += (2 - this.filterSelectionsShowClassworkTypes.length);
    return count;
  }
}
