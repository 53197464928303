
import { Component, Watch } from 'vue-property-decorator';
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import { namespace } from 'vuex-class';
import ClassesMixin from '@/mixins/classes-mixin';
import moment from 'moment';
import { VForm } from 'vuetify/lib';
import CommonUtils from '@/utils/common-utils';

const classes = namespace('classes');
const settings = namespace('settings');

@Component({
  mixins: [ClassesMixin],
  components: {
    BaseToolbar
  }
})
export default class ClassesToolbar extends BaseToolbar {
  editMode!: boolean;
  fullEditorEditMode!: boolean;
  scheduleName!: string;
  isRegularSchedule!: boolean;
  scheduleAsOfFC!: string;
  isEditingFromClassStart!: boolean;
  editScheduleAsOfFC = '';
  localClassEditScheduleMenu = false;
  localEditScheduleOption = '';
  localClassScheduleNewStartErrorMessage = '';
  localRefreshKey = CommonUtils.generateUUID();

  localRules = [
    (v: any) => !!v || 'Required'
  ];

  $refs!: {
    editScheduleMenuForm: InstanceType<typeof VForm>,
    dayMenuActivator: any,
    dayMenuList: any
  }

  @settings.Getter
  getCurrentYear!: any;

  @classes.Getter('getSpecialDayItems')
  specialDayItems!: any[];

  get localClassesComponent() {
    return this.currentPageInstance || {};
  }

  get scheduleAsOfLabel() {
    if (this.editMode) {
      return this.$t('editingScheduleAsOfLabel')
    } else {
      return this.$t('viewingScheduleAsOfLabel')
    }
  }

  get mCurrentDate() {
    return moment();
  }

  get mCurrentYearFirstDay() {
    return moment(this.getCurrentYear.firstDay, 'MM/DD/YYYY');
  }

  get mCurrentYearLastDay() {
    return moment(this.getCurrentYear.lastDay, 'MM/DD/YYYY');
  }

  focusEditButton() {
    try {
      const index = this.$refs.dayMenuList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      })
      if (index > 1) {
        (this.$refs as any)['editButton' + (index - 1)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusDeleteButton() {
    try {
      const index = this.$refs.dayMenuList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      })
      if (index > 1) {
        (this.$refs as any)['deleteButton' + (index - 1)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusDayList() {
    try {
      this.$refs.dayMenuActivator.$el.focus();
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  @Watch('isEditingFromClassStart')
  updateLocalEditScheduleOption() {
    if (!this.isEditingFromClassStart) {
      this.localEditScheduleOption = '';
    }
  }

  quickAddClass() {
    return this.localClassesComponent.quickAddClass();
  }

  @Watch('localClassEditScheduleMenu')
  onEditScheduleMenuOpen() {
    if (this.localClassEditScheduleMenu) {
      if (this.scheduleAsOfFC !== moment().format('MM/DD/YYYY') && this.scheduleAsOfFC !== this.getCurrentYear.firstDay) {
        this.editScheduleAsOfFC = this.scheduleAsOfFC
      } else {
        this.editScheduleAsOfFC = ''
      }
    }
  }

  toggleEditMode() {
    if (this.mCurrentDate.isBefore(this.mCurrentYearFirstDay)) {
      this.localEditScheduleOption = 'CS';
      this.isEditingFromClassStart = false;
      this.scheduleAsOfFC = this.getCurrentYear.firstDay;
      this.editScheduleAsOfFC = this.getCurrentYear.firstDay;
      this.editMode = !this.editMode;
    } else {
      if (!(!this.isRegularSchedule || this.mCurrentDate.isBefore(this.mCurrentYearFirstDay))) {
        const form: any = this.$refs.editScheduleMenuForm;
        if (!form.validate()) return;
      }
      this.localClassEditScheduleMenu = false;
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.removeQuicklyAddedClass();
        if (this.localEditScheduleOption === 'T') {
          this.scheduleAsOfFC = moment().format('MM/DD/YYYY');
        } else if (this.localEditScheduleOption === 'CS') {
          this.isEditingFromClassStart = true;
        } else if (this.localEditScheduleOption === 'O') {
          this.scheduleAsOfFC = this.editScheduleAsOfFC;
        }
      } else {
        this.editScheduleAsOfFC = '';
        this.localEditScheduleOption = 'T';
        if (this.isEditingFromClassStart) {
          this.isEditingFromClassStart = false;
          this.scheduleAsOfFCChanged();
        }
        this.localRefreshKey = CommonUtils.generateUUID();
      }
    }
  }

  removeQuicklyAddedClass() {
    return this.localClassesComponent.removeQuicklyAddedClass();
  }

  addAlternateDayModalShow() {
    return this.localClassesComponent.addAlternateDayModalShow();
  }

  switchToRegularOrAlternateSchedule(item: any) {
    return this.localClassesComponent.switchToRegularOrAlternateSchedule(item);
  }

  editAlternateDayModalShow(item: any) {
    return this.localClassesComponent.editAlternateDayModalShow(item);
  }

  deleteAlternateDayModalShow(item: any) {
    return this.localClassesComponent.deleteAlternateDayModalShow(item);
  }

  scheduleAsOfFCChanged() {
    return this.localClassesComponent.scheduleAsOfFCChanged();
  }

  created() {
    if (this.mCurrentDate.isBefore(this.mCurrentYearFirstDay)) {
      this.scheduleAsOfFC = this.getCurrentYear.firstDay;
    }
  }
}
